/**
 * Berechtigungstool
 * ...
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RuleEntity { 
    name?: string;
    facts?: Array<string>;
    operator?: RuleEntity.OperatorEnum;
    targetAttribute?: RuleEntity.TargetAttributeEnum;
    firstFact?: string;
}
export namespace RuleEntity {
    export type OperatorEnum = 'IN' | 'NIN' | 'EQ' | 'NEQ' | 'GT' | 'LT' | 'GTE' | 'LTE';
    export const OperatorEnum = {
        In: 'IN' as OperatorEnum,
        Nin: 'NIN' as OperatorEnum,
        Eq: 'EQ' as OperatorEnum,
        Neq: 'NEQ' as OperatorEnum,
        Gt: 'GT' as OperatorEnum,
        Lt: 'LT' as OperatorEnum,
        Gte: 'GTE' as OperatorEnum,
        Lte: 'LTE' as OperatorEnum
    };
    export type TargetAttributeEnum = 'DIREKTION' | 'DIREKTIONSBEREICH' | 'STRUKTURSTF' | 'PRAXISSTUFE' | 'VBNUMMER' | 'PERMISSION' | 'EXTRA_PERM' | 'EXTRA_ELEM' | 'MANDANT_DVAG' | 'MANDANT_ALLF' | 'MANDANT_ALLFAG' | 'MANDANT_OESI' | 'INNENDIENST' | 'DIREKTIONSLEITER' | 'BDV' | 'ASCLUB' | 'ASCLUBMBL' | 'KAPITANSCLUB' | 'ADMIRALCLUB' | 'ELITECLUB' | 'VB' | 'VM' | 'MITBENUTZER' | 'ASSISTENZ' | 'GASTZUGANG' | 'ALP' | 'HAUPTBERUF' | 'VBAK' | 'VBAS' | 'ASSISTENT_IM_CONTEXT' | 'STEUERSTATUS' | 'PREMIUM' | 'AUSTRITT' | 'P34F' | 'HR_AU_D2' | 'UMGEBUNG_LOKAL' | 'UMGEBUNG_ENTW' | 'UMGEBUNG_ABNAHME' | 'UMGEBUNG_PROD' | 'STUFE_VM01' | 'STUFE_VM02' | 'STUFE_VM03' | 'STUFE_VBAS' | 'STUFE_VBAK' | 'STUFE_ALP' | 'STUFE_AL' | 'STUFE_RGS' | 'STUFE_GS' | 'STUFE_HGS' | 'STUFE_RD1' | 'STUFE_RD2' | 'STUFE_PD' | 'STUFE_PRAXIS00' | 'STUFE_PRAXIS01' | 'STUFE_PRAXIS02' | 'STUFE_PRAXIS03' | 'STUFE_PRAXIS04' | 'STUFE_PRAXIS05' | 'STUFE_PRAXIS06' | 'STUFE_PRAXIS07';
    export const TargetAttributeEnum = {
        Direktion: 'DIREKTION' as TargetAttributeEnum,
        Direktionsbereich: 'DIREKTIONSBEREICH' as TargetAttributeEnum,
        Strukturstf: 'STRUKTURSTF' as TargetAttributeEnum,
        Praxisstufe: 'PRAXISSTUFE' as TargetAttributeEnum,
        Vbnummer: 'VBNUMMER' as TargetAttributeEnum,
        Permission: 'PERMISSION' as TargetAttributeEnum,
        ExtraPerm: 'EXTRA_PERM' as TargetAttributeEnum,
        ExtraElem: 'EXTRA_ELEM' as TargetAttributeEnum,
        MandantDvag: 'MANDANT_DVAG' as TargetAttributeEnum,
        MandantAllf: 'MANDANT_ALLF' as TargetAttributeEnum,
        MandantAllfag: 'MANDANT_ALLFAG' as TargetAttributeEnum,
        MandantOesi: 'MANDANT_OESI' as TargetAttributeEnum,
        Innendienst: 'INNENDIENST' as TargetAttributeEnum,
        Direktionsleiter: 'DIREKTIONSLEITER' as TargetAttributeEnum,
        Bdv: 'BDV' as TargetAttributeEnum,
        Asclub: 'ASCLUB' as TargetAttributeEnum,
        Asclubmbl: 'ASCLUBMBL' as TargetAttributeEnum,
        Kapitansclub: 'KAPITANSCLUB' as TargetAttributeEnum,
        Admiralclub: 'ADMIRALCLUB' as TargetAttributeEnum,
        Eliteclub: 'ELITECLUB' as TargetAttributeEnum,
        Vb: 'VB' as TargetAttributeEnum,
        Vm: 'VM' as TargetAttributeEnum,
        Mitbenutzer: 'MITBENUTZER' as TargetAttributeEnum,
        Assistenz: 'ASSISTENZ' as TargetAttributeEnum,
        Gastzugang: 'GASTZUGANG' as TargetAttributeEnum,
        Alp: 'ALP' as TargetAttributeEnum,
        Hauptberuf: 'HAUPTBERUF' as TargetAttributeEnum,
        Vbak: 'VBAK' as TargetAttributeEnum,
        Vbas: 'VBAS' as TargetAttributeEnum,
        AssistentImContext: 'ASSISTENT_IM_CONTEXT' as TargetAttributeEnum,
        Steuerstatus: 'STEUERSTATUS' as TargetAttributeEnum,
        Premium: 'PREMIUM' as TargetAttributeEnum,
        Austritt: 'AUSTRITT' as TargetAttributeEnum,
        P34F: 'P34F' as TargetAttributeEnum,
        HrAuD2: 'HR_AU_D2' as TargetAttributeEnum,
        UmgebungLokal: 'UMGEBUNG_LOKAL' as TargetAttributeEnum,
        UmgebungEntw: 'UMGEBUNG_ENTW' as TargetAttributeEnum,
        UmgebungAbnahme: 'UMGEBUNG_ABNAHME' as TargetAttributeEnum,
        UmgebungProd: 'UMGEBUNG_PROD' as TargetAttributeEnum,
        StufeVm01: 'STUFE_VM01' as TargetAttributeEnum,
        StufeVm02: 'STUFE_VM02' as TargetAttributeEnum,
        StufeVm03: 'STUFE_VM03' as TargetAttributeEnum,
        StufeVbas: 'STUFE_VBAS' as TargetAttributeEnum,
        StufeVbak: 'STUFE_VBAK' as TargetAttributeEnum,
        StufeAlp: 'STUFE_ALP' as TargetAttributeEnum,
        StufeAl: 'STUFE_AL' as TargetAttributeEnum,
        StufeRgs: 'STUFE_RGS' as TargetAttributeEnum,
        StufeGs: 'STUFE_GS' as TargetAttributeEnum,
        StufeHgs: 'STUFE_HGS' as TargetAttributeEnum,
        StufeRd1: 'STUFE_RD1' as TargetAttributeEnum,
        StufeRd2: 'STUFE_RD2' as TargetAttributeEnum,
        StufePd: 'STUFE_PD' as TargetAttributeEnum,
        StufePraxis00: 'STUFE_PRAXIS00' as TargetAttributeEnum,
        StufePraxis01: 'STUFE_PRAXIS01' as TargetAttributeEnum,
        StufePraxis02: 'STUFE_PRAXIS02' as TargetAttributeEnum,
        StufePraxis03: 'STUFE_PRAXIS03' as TargetAttributeEnum,
        StufePraxis04: 'STUFE_PRAXIS04' as TargetAttributeEnum,
        StufePraxis05: 'STUFE_PRAXIS05' as TargetAttributeEnum,
        StufePraxis06: 'STUFE_PRAXIS06' as TargetAttributeEnum,
        StufePraxis07: 'STUFE_PRAXIS07' as TargetAttributeEnum
    };
}


