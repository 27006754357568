import { Inject, Injectable } from '@angular/core'
import { UserServiceJwtV2Service as UserService } from '../../generated/openapi/userservice'
import { DynamicRuleServiceService as DynamicRuleService, ResponseBoolean } from '../../generated/openapi/berechtigungstool'
import { Router } from '@angular/router'
import { firstValueFrom, Observable } from 'rxjs'
import { EnvironmentService } from './environment.service'
import { KeycloakService } from 'keycloak-angular'
import { DOCUMENT } from '@angular/common'
import { map, shareReplay } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { tUser } from '../types'
import { cookieCreate } from '../utils'
import { InsightsService } from './insights.service'

export type ExternalTarget = {
  target: string,
  partner: string,
  ts: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user$: Observable<tUser> = new Observable<tUser>()
  private keycloakInitialized = false

  constructor(
    public router: Router,
    private http: HttpClient,
    private userService: UserService,
    private dynamicRuleService: DynamicRuleService,
    private envService: EnvironmentService,
    private keycloak: KeycloakService,
    private insightsService: InsightsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject('locationObject') private locationObject: Location
  ) {
    // empty
  }

  async getUser(): Promise<tUser> {
    return firstValueFrom(this.user$)
  }

  async getSSOId(apiUrl: string): Promise<string> {
    const data = await firstValueFrom(this.http.get<{
      'x-compeople-ssoid': string,
      'x-zob-ssoid': string
    }>(apiUrl, {
      headers: {
        'Authorization': 'Bearer ' + await this.keycloak.getToken()
      }
    }))

    return data["x-compeople-ssoid"]
  }

  async getConfig() {
    const config = await this.envService.getConfig()
    const keycloakInstance = this.keycloak.getKeycloakInstance()

    this.dynamicRuleService.configuration.basePath = config.apis.berechtigungsToolUrl
    this.dynamicRuleService.configuration.credentials = {
      'Bearer Authentication': keycloakInstance.token || ''
    }
    this.userService.configuration.basePath = config.apis.userServiceUrl
    this.userService.configuration.credentials = {
      'Bearer Authentication': keycloakInstance.token || ''
    }

    const tokenParsed = keycloakInstance.tokenParsed
    const context = tokenParsed!['context']
    const hasTextservicePermission: ResponseBoolean = await this.checkHasTextservicePermission(
      tokenParsed?.preferred_username
    )

    this.user$ = this.userService.requestUser()
      .pipe(map(user => {
        user.firstName = tokenParsed?.given_name
        user.lastName = tokenParsed?.family_name
        user.userId = tokenParsed?.preferred_username

        /* user_types: no - there is no number 7
          {1:"AUSSENDIENST", 2:"INNENDIENST", "3:MITBENUTZER", 4:"HOTLINE", 5:"AUTOTEST", 6:"KONZERN_MITARBEITER", 8:"ASSISTENT", 9:"SERVICE_ACCOUNT"}
         */
        return {
          ...user, ...{
            isAssistent: tokenParsed?.user_typ === '8',
            isVertreter: !!(tokenParsed?.user_typ !== '8' && context && context.prefered_username != tokenParsed?.preferred_username),
            context: {name: context?.family_name, vorname: context?.given_name},
            isInnendienst: tokenParsed?.user_typ === '2',
            isEditor: hasTextservicePermission.data || false
          }
        }
      })).pipe(shareReplay())
  }

  private async checkHasTextservicePermission(userId: string): Promise<ResponseBoolean> {
    return firstValueFrom(this.dynamicRuleService.calcBoolean({
      userid: userId,
      app: 'textservice',
      element: 'meinedvag'
    })).catch((e: Error) => {
      return {data: false}
    })
  }

  async initializeKeycloak() {
    // no authentication when health page is called
    if (this.document.location.hash.includes('health')) {
      return
    }

    const config = await this.envService.getConfig()

    try {
      this.keycloakInitialized = await this.keycloak.init({
        config: {
          url: config.apis.keycloak,
          realm: config.apis.keycloakRealm,
          clientId: config.apis.keycloakClientId
        },
        initOptions: {
          onLoad: 'check-sso',
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri: window.location.origin + config.apis.deployUrl + '/assets/silent-check-sso.html'
        }
      })

      if (!this.keycloakInitialized) {
        if (!this.isLoggedIn()) {
          await this.checkSsoOnIdp()
        } else {
          console.log('UinfoKeycloakService: Failed to initialize Keycloak - no success')
          this.insightsService.logException({message: 'Failed to initialize Keycloak - no success', name: 'NoSuccessInitKeycloak'})
          this.router.navigate(['autherrorpage'])
        }
      }

      if (this.keycloakInitialized) {
        await this.getConfig()
        this.setSSOCookie(await this.getSSOId(config.apis.ssoInfoUrl))
        this.checkIfRedirected(document.cookie)
        this.loadBefoerderungspopup().then()
      }
    } catch (reason) {
      console.log('Failed to retrieve access token: ' + reason)
    }
  }

  public async getToken(): Promise<string> {
    await this.updateTokenIfNeeded()
    return this.keycloak.getToken()
  }

  public getDecodedToken() {
    return this.keycloak.getKeycloakInstance().tokenParsed
  }

  public async updateTokenIfNeeded(retryCount: number = 3): Promise<void> {
    if (!this.isLoggedIn()) {
      if (this.keycloakInitialized) {
        await this.checkSsoOnIdp()
      }
      return
    }

    if (this.keycloak.isTokenExpired(60)) {
      for (let attempt = 0; attempt <= retryCount; attempt++) {
        try {
          const success = await this.keycloak.updateToken(60)
          if (!success) {
            this.insightsService.logException({message: 'Failed to update access token - no success', name: 'NoSuccessUpdateToken'})
            throw Error('Token update failed')
          } else {
            return
          }
        } catch (error) {
          if (attempt === retryCount) {
            this.insightsService.logException({message: 'Failed to update access token - error', name: 'ErrorUpdateToken'})
            if (!this.keycloakInitialized) {
              this.insightsService.logException({message: 'Token update after 3 retries failed', name: 'NoSuccessUpdateTokenAfterRetries'})
              this.router.navigate(['autherrorpage'])
            }
          }
        }
      }
    }
  }

  public isLoggedIn(): boolean {
    return this.keycloak.isLoggedIn()
  }

  public async checkSsoOnIdp(): Promise<void> {
    await this.keycloak.login({redirectUri: this.locationObject.href})
  }

  async loadBefoerderungspopup() {
    const config = await this.envService.getConfig()

    // load befoerderungs-popup script in head
    let scriptNode = this.document.createElement('script')
    scriptNode.src = config.apis.befoerderungsPopup
    scriptNode.type = 'text/javascript'
    scriptNode.defer = true
    this.document.getElementsByTagName('head')[0].appendChild(scriptNode)
  }

  checkIfRedirected(cookie: string) {
    const entry = cookie.split(';').find((c: string) => c.trim().startsWith('externalTarget='))
    const externalTarget = entry?.split('=')[1] || ''

    if (externalTarget) {
      const { target } = <ExternalTarget>JSON.parse(decodeURIComponent(externalTarget))
      this.router.navigate([]).then(() => {
        this.doRedirect(target)
      })
    }
  }

  doRedirect(url: string) {
    this.document.location.href = url
  }

  setSSOCookie(ssoid: string) {
    cookieCreate('x-compeople-ssoid', ssoid)
  }
}
