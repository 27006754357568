import { Tag } from '../generated/openapi/tags'
import { Anwendungssuche } from '../generated/openapi/search/anwendungen'
import { User } from '../generated/openapi/userservice'

export enum ecsTargets {
  WELTEN = 'welten',
  INTRANET = 'intranet'
}

export enum searchMandanten {
  'meineDVAG-Keycloak' = 'meineDVAG-Keycloak',
  'meineDVAG-ID-Keycloak' = 'meineDVAG-ID-Keycloak'
}

export type tEcsSearch = {
  [key in ecsTargets]: tEcsSearchItem
}

export type tApps = {
  [key in searchMandanten]: Array<Anwendungssuche>
}

export type tEcsSearchAutoComplete = {
  [key: string]: tEcsSearchItem
  intranet: tEcsSearchItem
  welten: tEcsSearchItem
}

export type tEcsSearchItem = {
  baseURL: URL
  result: tEcsSearchItemResult
  status: boolean
}

export type tEcsSearchItemAutoComplete = {
  baseURL: URL
  result: tEcsSearchAutoCompleteItemResult
  status: boolean
}

export type tEcsSearchAutoCompleteItemResult = {
  id: string
  label: string
  value: string
}

export type tEcsSearchItemResult = {
  ContentsTags: Array<string>
  Tags: Array<Tag>
  FilesResults: Array<tEcsSearchItemResultItem>
  FilesResultsCount: number
  IsFileSearch: boolean
  ItemsCount: number
  PromotedResults: Array<any>
  PromotedResultsCount: number
  Query: string
  Results: Array<tEcsSearchItemResultItem>
  Root: string
}

export type tEcsSearchItemResultItem = {
  Title: string
  AdditionalInfo: string
  ContentId: number
  ContentType: string
  ContentVersionId: number
  FileUrl: string
  Image: string
  PublishDate: string
  ResultType: number
  ShowFullImage: boolean
  StatusName: string
  Text: string
  Type: string
  Url: string
}

export type Version = {
  buildTime: number
}

export type ExcludedTelemetryItem = {
  url: string,
  responseCode?: number
}

export type tUser = User & {
  context?: {
    vorname: string
    name: string
  },
  isAssistent: boolean,
  isVertreter: boolean,
  isInnendienst: boolean,
  isEditor: boolean
}
