import { EventEmitter, Injectable } from '@angular/core'
import {
  Anwendungssuche,
  DefaultService as AnwendungssucheApi,
  SucheMandantGetRequestParams
} from '../../generated/openapi/search/anwendungen'
import { firstValueFrom, Observable } from 'rxjs'
import {
  DefaultService as FavoritenApi,
  FavoritenGetVbNummerGetRequestParams, Favorit, Favoriten
} from '../../generated/openapi/favoriten'
import { AuthService } from './auth.service'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { searchMandanten, tApps } from '../types'
import { EnvironmentService } from './environment.service'
import { KeycloakService } from 'keycloak-angular'
import { InsightsService } from './insights.service'

@Injectable({
  providedIn: 'root'
})
export class AnwendungssucheService {

  private _apps: tApps = {
    'meineDVAG-Keycloak': [],
    'meineDVAG-ID-Keycloak': []
  }
  private _favorites: Favoriten = {
    favoritSet: [],
    vbNummer: '8573100'
  }
  private _lastSearchTerm = ''
  private _lastSearchCount = 999
  private _lastSearchPage = 1
  private _lastSearchMandant!: searchMandanten
  private _userId = ''
  public favChanged$: EventEmitter<{ id: string, status: boolean }> = new EventEmitter<any>()

  constructor(
    private AnwendungssucheApi: AnwendungssucheApi,
    private favoritenApi: FavoritenApi,
    private authService: AuthService,
    private gaService: GoogleAnalyticsService,
    private envService: EnvironmentService,
    private insightsService: InsightsService,
    private keycloak: KeycloakService) {
    this.loadUser()
    this.fetchFavoriteApps()
  }

  fetchApps(mandant: searchMandanten, term: string = '', count: number = 999, page: number = 1): Observable<tApps> {
    return new Observable<tApps>((observer) => {
      if (
        this._apps[mandant]?.length &&
        this._lastSearchTerm === term &&
        this._lastSearchCount === count &&
        this._lastSearchPage === page &&
        this._lastSearchMandant === mandant
      ) {
        observer.next(this.apps)
      } else {
        this._lastSearchMandant = mandant
        this.envService.getConfig()
          .then(async (config) => {
            this.AnwendungssucheApi.configuration.basePath = config.apis.appDefaultSearch || 'https://meine.dvag/anwendungssuche'
            this.AnwendungssucheApi.configuration.credentials = {
              'bearerAuth': await this.keycloak.getToken()
            }
            this.AnwendungssucheApi.sucheMandantGet(<SucheMandantGetRequestParams>{
              mandant: mandant || 'meineDVAG-Keycloak',
              query: term || undefined
            }).subscribe((apps) => {
              this._apps[mandant] = apps.results?.map(app => {
                app.title = app.title || ''
                app.isFav = !!this._favorites?.favoritSet?.filter((fav) => fav.anwendungsId === app.id).length
                return app
              }) || []
              this._lastSearchTerm = term || ''
              observer.next(this.apps)
            })
          })
          .catch((err) => {
            observer.next(this.apps)
          })
      }
    })
  }

  async fetchFavoriteApps() {
    const config = await this.envService.getConfig()
    this.favoritenApi.configuration.basePath = config.apis.favorites
    this.favoritenApi.configuration.credentials = {
      'bearerAuth': await this.keycloak.getToken()
    }

    let user = await this.authService.getUser()
    const favorites = await firstValueFrom(this.favoritenApi.favoritenGetVbNummerGet(<FavoritenGetVbNummerGetRequestParams>{
      vbNummer: user.userId
    }))

    if (Object.keys(favorites).length) {
      this._favorites = favorites
    }

    this.favChanged$.emit()
  }

  async loadUser() {
    let user = await this.authService.getUser()
    this._userId = user.userId || ''
    this._favorites.vbNummer = this._userId
  }

  // comment for test
  get apps() {
    return this._apps
  }

  get favorites(): Array<Favorit> {
    return this._favorites?.favoritSet
  }

  set favorites(favs) {
    this._favorites.favoritSet = favs
  }

  get favoriteApps(): Array<Anwendungssuche> {
    let apps: Array<Anwendungssuche> = []
    for (let mandant in searchMandanten) {
      const _mandant = searchMandanten[mandant as keyof typeof searchMandanten]
      apps = apps.concat(this.apps[_mandant])
    }

    const filteredApps = apps.reduce((filtered: Array<Anwendungssuche>, app) => {
      let favApps =
        this._favorites?.favoritSet?.filter(favApp => (favApp.anwendungsId === app.id) && app.isVisible)

      if (favApps?.length) {
        filtered[(favApps[0].pos || 0)] = app
      }

      return filtered
    }, [])
    return filteredApps.filter((app) => app !== undefined)
  }

  setApps(apps: tApps) {
    this._apps = apps
  }

  getRecommendedApps(mandant: searchMandanten): Array<Anwendungssuche> {
    let _recommended: Array<Anwendungssuche> = []
    _recommended = _recommended.concat(this._apps[mandant].filter(app => app.isFeatured))

    return _recommended.sort((a: Anwendungssuche, b: Anwendungssuche) => a.featuredPos - b.featuredPos)
  }

  async setFavorite(app: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this._favorites?.favoritSet?.push({
        anwendungsId: app,
        pos: this._favorites?.favoritSet?.length
      })
      this.favoritenApi.configuration.credentials = {
        'bearerAuth': await this.keycloak.getToken()
      }
      this.favoritenApi.favoritenSetPost({
        favoriten: this._favorites
      }).subscribe((observer: Favoriten) => {
        if (Object.keys(observer).length) {
          this._favorites.favoritSet = observer.favoritSet
        }
        resolve(true)
        this.favChanged$.emit({status: true, id: app})
      })
    })
  }

  async writeFavorites(favs: Favorit[]) {
    this._favorites.favoritSet = favs
    this.favoritenApi.configuration.credentials = {
      'bearerAuth': await this.keycloak.getToken()
    }
    this.favoritenApi.favoritenSetPost({
      favoriten: this._favorites
    }).subscribe()
  }

  rebuildFavsPositions(): void {
    this._favorites.favoritSet.forEach((fav, index) => {
      this._favorites.favoritSet[index].pos = index
    })
  }

  removeFavorite(app: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this._favorites.favoritSet =
        this._favorites?.favoritSet?.filter((fav) => fav.anwendungsId !== app)

      // sort apps by pos before rebuilding positions
      this._favorites.favoritSet.sort((a: Favorit, b: Favorit) => a.pos - b.pos)

      this.rebuildFavsPositions()

      this.favoritenApi.configuration.credentials = {
        'bearerAuth': await this.keycloak.getToken()
      }
      this.favoritenApi.favoritenSetPost({
        favoriten: this._favorites
      }).subscribe((observer: Favoriten) => {
        if (Object.keys(observer).length) {
          this._favorites.favoritSet = observer.favoritSet
        }
        resolve(true)

        let title = ''
        for (let mandant in searchMandanten) {
          const _mandant = searchMandanten[mandant as keyof typeof searchMandanten]
          const _title = this._apps[_mandant].filter((_app) => _app.id === app)[0]?.title
          if (_title) title = _title
        }

        this.gaService.event(
          'favorit entfernen',
          'Redesign:Anwendungen im Ueberblick>Favorit entfernen',
          title
        )
        this.insightsService.logEvent('Favorit entfernt: ' + title)
        this.favChanged$.emit({status: false, id: app})
      })
    })
  }
}
